import { createRouter,createWebHashHistory } from "vue-router";


const routes = [
    {
        path:"/",
        redirect: "/index",
        name:"home",
        component:()=>import("@/views/home.vue"),
        children:[
            {
                path:"/index",
                name:"首页",
                // redirect: "/index",
                component:()=>import("@/views/homePage/index.vue"),
                
            },
            {
                path:"/vipHome",
                name:"同学会",
                redirect: "/memberHome",
                component:()=>import("@/views/vipHome/index.vue"),
                children:[
                    {
                        path:"/memberHome",
                        name:"社团",
                        component:()=>import("@/views/vipHome/memberHome/index.vue"),
                    },
                    {
                        path:"/branch",
                        name:"班级",
                        component:()=>import("@/views/vipStyle/vip-branch/index.vue"),
                    },
                    {
                        path:"/elegant",
                        name:"同学圈",
                        component:()=>import("@/views/vipStyle/vip-elegant/index.vue"),
                    }
                ]
            },  
            {
                path:"/helloPage",
                name:"聊天室",
                redirect: "/helloPageChat",
                component:()=>import("@/views/helloPage/index.vue"),
                children:[
                    {
                        path:"/helloPageChat",
                        name:"聊天",
                        component:()=>import("@/views/helloPage/helloPage-chat/index.vue")
                    },
                    {
                        path:"/helloPageMailList",
                        name:"通讯录",
                        component:()=>import("@/views/helloPage/helloPage-mailList/index.vue"),
                    }
                ]
            },  
            {
                path:"/vipStyle",
                name:"发现",
                redirect: "/helloPageCircle",
                component:()=>import("@/views/vipStyle/index.vue"),
                children:[
                    {
                        path:"/helloPageCircle",
                        name:"动态圈",
                        component:()=>import("@/views/helloPage/helloPage-circle/index.vue"),
                    },
                    // {
                    //     path:"/elegant",
                    //     name:"同学圈",
                    //     component:()=>import("@/views/vipStyle/vip-elegant/index.vue"),
                    // }
                ]
            },     
            {
                path:"/personalCenter",
                name:"我的",
                component:()=>import("@/views/personalCenter/index.vue")
            },
        ]
        
    },
    {
        path:"/creationVipHome",
        name:"创建会员之家",
        component:()=>import("@/views/vipHome/creationVipHome/index.vue"),
    }, 
    {
        path:"/selectMember",
        name:"选择联系人",
        component:()=>import("@/views/vipHome/creationVipHome/selectMember/index.vue"),
    },
    {
        path:"/messageList",
        name:"聊天对话",
        component:()=>import("@/views/helloPage/helloPage-chat/message-list/messageList.vue"),
    },  
    {
        path:"/myMessage",
        name:"我的消息",
        component:()=>import("@/views/helloPage/helloPage-chat/myMessage/index.vue"),
    }, 
    {
        path:"/friendDataSet",
        name:"资料设置",
        component:()=>import("@/views/helloPage/helloPage-chat/friendDataSet/index.vue"),
    }, 
    {
        path:"/launchGroupChat",
        name:"发起群聊",
        component:()=>import("@/views/helloPage/helloPage-mailList/launchGroupChat/index.vue"),
    }, 
    {
        path:"/groupChatList",
        name:"群聊信息",
        component:()=>import("@/views/helloPage/helloPage-mailList/launchGroupChat/groupChat-list/index.vue"),
    },  
    {
        path:"/groupChatMsg",
        name:"聊天信息",
        component:()=>import("@/views/helloPage/helloPage-mailList/launchGroupChat/groupChat-msg/index.vue"),
    }, 
    {
        path:"/groupChatMembers",
        name:"群成员",
        component:()=>import("@/views/helloPage/helloPage-mailList/launchGroupChat/groupChat-members/index.vue"),
    }, 
    {
        path:"/personalDetail",
        name:"个人详情",
        component:()=>import("@/views/personalDetail/index.vue")
    },
    {
        path:"/managementVipHome",
        name:"管理会员之家",
        component:()=>import("@/views/vipHome/managementVipHome/index.vue")
    },
    {
        path:"/vipHomeOperatingRecord",
        name:"会员之家操作记录",
        component:()=>import("@/views/vipHome/managementVipHome/vipHomeOperatingRecord/index.vue")
    },
    {
        path:"/auditVipHome",
        name:"审核会员之家",
        component:()=>import("@/views/vipHome/auditVipHome/index.vue")
    },
    {
        path:"/managementVipHomeCompile",
        name:"编辑会员之家",
        component:()=>import("@/views/vipHome/managementVipHomeCompile/index.vue")
    },
    {
        path:"/editSelectMember",
        name:"编辑会员之家选择联系人",
        component:()=>import("@/views/vipHome/managementVipHomeCompile/selectMember/index.vue"),
    },
    {
        path:"/operatingRecord",
        name:"操作记录",
        component:()=>import("@/views/publicPage/operatingRecord/index.vue")
    },
    {
        path:"/mapPage",
        name:"地图页面",
        component:()=>import("@/views/publicPage/mapPage/index.vue")
    },
    {
        path:"/lookMapAddress",
        name:"地图查看位置",
        component:()=>import("@/views/publicPage/lookMapAddress/index.vue")
    },
    {
        path:"/sendDynamicCondition",
        name:"发动态、闲置物、勤工俭学、代练陪玩",
        component:()=>import("@/views/dynamicManagement/sendDynamicCondition/index.vue")
    },
    {
        path:"/dynamicManagement",
        name:"动态管理",
        component:()=>import("@/views/dynamicManagement/index.vue")
    },
    {
        path:"/dynamicDetails",
        name:"动态详情",
        component:()=>import("@/views/dynamicManagement/dynamicDetails/index.vue")
    }, 
    {
        path:"/dynamicDetailReview",
        name:"动态详情评论",
        component:()=>import("@/views/dynamicManagement/dynamicDetailReview/index.vue")
    },
    {
        path:"/examineAndApprove",
        name:"审批",
        component:()=>import("@/views/examineAndApprove/index.vue")
    },
    {
        path:"/sendExamineAndApprove",
        name:"发起审批",
        component:()=>import("@/views/examineAndApprove/sendExamineAndApprove/index.vue")
    },
    {
        path:"/examineAndApproveDetail",
        name:"审批详情",
        component:()=>import("@/views/examineAndApprove/examineAndApproveDetail/index.vue")
    },
    {
        path:"/personnelSelection",
        name:"选择审批人",
        component:()=>import("@/views/examineAndApprove/personnelSelection/index.vue")
    },
    {
        path:"/personnelSelectionCareOf",
        name:"选择转交审批人",
        component:()=>import("@/views/examineAndApprove/personnelSelectionCareOf/index.vue")
    },
    {
        path:"/selectPerson",
        name:"选择告知人",
        component:()=>import("@/views/examineAndApprove/selectPerson/index.vue")
    },
    {
        path:"/registerBook",
        name:"名册",
        component:()=>import("@/views/registerBook/index.vue")
    },
    {
        path:"/applyForList",
        name:"申请列表",
        component:()=>import("@/views/applyForList/index.vue")
    },
    {
        path:"/newsManagement",
        name:"平台新闻",
        component:()=>import("@/views/newsManagement/index.vue")
    },
    {
        path:"/cnNewsManagement",
        name:"新闻",
        component:()=>import("@/views/newsManagement/cnNewsManagement/index.vue")
    },
    {
        path:"/sendNews",
        name:"发布新闻",
        component:()=>import("@/views/newsManagement/sendNews/index.vue")
    },
    {
        path:"/cnSendNews",
        name:"班级发布新闻",
        component:()=>import("@/views/newsManagement/cnSendNews/index.vue")
    },
    {
        path:"/newDetails",
        name:"新闻详情",
        component:()=>import("@/views/newsManagement/newDetails/index.vue")
    },
    {
        path:"/unLoginNewDetail",
        name:"未登录新闻详情",
        component:()=>import("@/views/newsManagement/unLoginNewDetail/index.vue")
    },
    {
        path:"/newsDetailReview",
        name:"新闻评论管理",
        component:()=>import("@/views/newsManagement/newsDetailReview/index.vue")
    },
    {
        path:"/accountingDepartment",
        name:"财务",
        component:()=>import("@/views/accountingDepartment/index.vue")
    },
    {
        path:"/addIncome",
        name:"添加收入",
        component:()=>import("@/views/accountingDepartment/addIncome/index.vue")
    },
    {
        path:"/cnAddIncome",
        name:"班级添加收入",
        component:()=>import("@/views/accountingDepartment/cnAddIncome/index.vue")
    },
    {
        path:"/addSpending",
        name:"添加支出",
        component:()=>import("@/views/accountingDepartment/addSpending/index.vue")
    },
    {
        path:"/cnAddSpending",
        name:"班级添加支出",
        component:()=>import("@/views/accountingDepartment/cnAddSpending/index.vue")
    },
    {
        path:"/incomeDetails",
        name:"收支详情",
        component:()=>import("@/views/accountingDepartment/incomeDetails/index.vue")
    },
    {
        path:"/participatePolitical",
        name:"参政议政",
        component:()=>import("@/views/participatePolitical/index.vue")
    },
    {
        path:"/uploadingParticipatePolitical",
        name:"发布参政议政",
        component:()=>import("@/views/participatePolitical/uploadingParticipatePolitical/index.vue")
    },
    {
        path:"/participatePoliticalDetail",
        name:"参政议政详情",
        component:()=>import("@/views/participatePolitical/participatePoliticalDetail/index.vue")
    },
    {
        path:"/cultivate",
        name:"培训",
        component:()=>import("@/views/cultivate/index.vue")
    },
    {
        path:"/deliveringTraining",
        name:"发布培训",
        component:()=>import("@/views/cultivate/deliveringTraining/index.vue")
    },
    {
        path:"/deliveringTrainingDetail",
        name:"培训详情",
        component:()=>import("@/views/cultivate/deliveringTrainingDetail/index.vue")
    },
    {
        path:"/announcement",
        name:"公告",
        component:()=>import("@/views/announcement/index.vue")
    },
    {
        path:"/announce",
        name:"发布公告",
        component:()=>import("@/views/announcement/announce/index.vue")
    },
    {
        path:"/cnAnnounce",
        name:"班级发布公告",
        component:()=>import("@/views/announcement/cnAnnounce/index.vue")
    },
    {
        path:"/announceDetail",
        name:"公告详情",
        component:()=>import("@/views/announcement/announceDetail/index.vue")
    },
    {
        path:"/personnelSelectionAnnoun",
        name:"公告发布联系人列表",
        component:()=>import("@/views/announcement/personnelSelectionAnnoun/index.vue")
    },
    {
        path:"/cnPersonnelSelectionAnnoun",
        name:"班级公告发布联系人列表",
        component:()=>import("@/views/announcement/cnPersonnelSelectionAnnoun/index.vue")
    },
    {
        path:"/setPage",
        name:"设置",
        component:()=>import("@/views/setPage/index.vue")
    },
    {
        path:"/accountManagement",
        name:"账号管理",
        component:()=>import("@/views/setPage/accountManagement/index.vue")
    },
    {
        path:"/aboutUs",
        name:"关于我们",
        component:()=>import("@/views/setPage/aboutUs/index.vue")
    },
    {
        path:"/editData",
        name:"编辑资料",
        component:()=>import("@/views/setPage/editData/index.vue")
    },
    {
        path:"/companyInformation",
        name:"公司/机构",
        component:()=>import("@/views/setPage/editData/companyInformation/index.vue")
    },
    {
        path:"/minjianPage",
        name:"南洋学院官网",
        component:()=>import("@/views/minjianPage/index.vue")
    },
    {
        path:"/attentionPage",
        name:"关注",
        component:()=>import("@/views/publicPage/attentionPage/index.vue")
    },
    {
        path:"/beanVermicelliPage",
        name:"粉丝",
        component:()=>import("@/views/publicPage/beanVermicelliPage/index.vue")
    },
    {
        path:"/visitRegistration",
        name:"拜访登记",
        component:()=>import("@/views/businessCard/visitRegistration/index.vue")
    },
    {
        path:"/nameCardHolder",
        name:"名片夹",
        component:()=>import("@/views/businessCard/nameCardHolder/index.vue")
    },
    {
        path:"/setCard",
        name:"名片设置",
        component:()=>import("@/views/businessCard/setCard/index.vue")
    },
    {
        path:"/identityDetailPage",
        name:"实名认证",
        component:()=>import("@/views/setPage/editData/identityDetailPage/index.vue")
    },
    {
        path:"/shareCard",
        name:"分享名片",
        component:()=>import("@/views/businessCard/shareCard/index.vue")
    },
    {
        path:"/userManagement",
        name:"用户管理",
        component:()=>import("@/views/userManagement/index.vue")
    },
    {
        path:"/permissionsManagement",
        name:"权限管理",
        component:()=>import("@/views/permissionsManagement/index.vue")
    },
    {
        path:"/platformPermissionsAdd",
        name:"平台子管理员",
        component:()=>import("@/views/permissionsManagement/platformPermissions/platformPermissionsAdd/index.vue")
    },
    {
        path:"/personnelSelectionPlatfrom",
        name:"平台子管理员选择成员列表",
        component:()=>import("@/views/permissionsManagement/platformPermissions/platformPermissionsAdd/personnelSelectionPlatfrom/index.vue")
    },
    {
        path:"/branchPermissions",
        name:"班级管理权限",
        component:()=>import("@/views/permissionsManagement/branchPermissions/index.vue")
    },
    {
        path:"/branchIntroduction",
        name:"班级简介",
        component:()=>import("@/views/branchIntroduction/index.vue")
    },
    {
        path:"/mapNavigationPage",
        name:"地图导航",
        component:()=>import("@/views/mapNavigationPage/index.vue")
    },
    {
        path:"/downloadCard",
        name:"下载名片",
        component:()=>import("@/views/businessCard/downloadCard/index.vue")
    },
    {
        path:"/clubBook",
        name:"社团名册",
        component:()=>import("@/views/clubManagement/clubBook/index.vue")
    },
    {
        path:"/clubApplyList",
        name:"社团申请列表",
        component:()=>import("@/views/clubManagement/clubApplyList/index.vue")
    },
    {
        path:"/clubPermissions",
        name:"社团管理权限",
        component:()=>import("@/views/clubManagement/clubPermissions/index.vue")
    },
    {
        path:"/clubPermissionsAdd",
        name:"社团子管理员",
        component:()=>import("@/views/clubManagement/clubPermissionsAdd/index.vue")
    },
    {
        path:"/clubSelectionPlatfrom",
        name:"社团子管理员选择成员列表",
        component:()=>import("@/views/clubManagement/clubSelectionPlatfrom/index.vue")
    },
    {
        path:"/clubInfo",
        name:"社团信息",
        component:()=>import("@/views/clubManagement/clubInfo/index.vue")
    },
    {
        path:"/auditClub",
        name:"管理社团",
        component:()=>import("@/views/clubManagement/auditClub/index.vue")
    },
    {
        path:"/auditClubContent",
        name:"审核社团",
        component:()=>import("@/views/clubManagement/auditClubContent/index.vue")
    },
    {
        path:"/creationClub",
        name:"创建社团",
        component:()=>import("@/views/clubManagement/creationClub/index.vue"),
    },
    {
        path:"/editClub",
        name:"编辑社团",
        component:()=>import("@/views/clubManagement/editClub/index.vue")
    }
]

export default createRouter({
    history:createWebHashHistory(),
    routes:routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})